@use 'vars.scss';

.colorset-a {
    background-color: vars.$color-a;
    color: vars.$color-white;

    .section-header {
        color: vars.$color-d;
    }

    .section-header-color {
        color: vars.$color-d;
    }

    .section-date {
        color: vars.$color-b;
    }

    .content-container {

        h1,
        h2,
        h3,
        h4 {
            color: vars.$color-d;
        }
    }
}

.colorset-b {
    background-color: vars.$color-b;
    color: vars.$color-white;

    .section-header {
        color: vars.$color-c;
    }

    .section-header-color {
        color: vars.$color-c;
    }

    .section-date {
        color: vars.$color-d;
    }

    .content-container {

        h1,
        h2,
        h3,
        h4 {
            color: vars.$color-c;
        }
    }
}
