$color-a: #D6B43E;
$color-b: #547076;
$color-c: #C2AE93;
$color-d: #793327;
$color-white: #f2f2f2;
$color-black: #2f2f2f;

$section-header-font: futura;
// $ul-font: 1.4em Futura  Lt Bt;
$ul-font: 1.2em iosevka-light;
$content-font: futura-light;
// $content-font: Iosevka;
$code-font: iosevka-light;
$h-font: iosevka-regular;

// $mobile-max-width: 900px;
$subsection-container-max-width: 1400px;

$container-border-margin: 1.75rem;
$master-padding-top: 100px;
$bullet-li-spacing: 0.5em;

$main-container-max-width: 500px;
$main-container-min-width: 450px;
$main-v-axis: 35vw;

$computed-main-v-axis: max($main-v-axis, $main-container-min-width);
$main-margin-left: calc($computed-main-v-axis - $main-container-max-width);