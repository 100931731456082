@use 'vars.scss';

.justified-gallery-container {
    background-color: vars.$color-black;
    // border-radius: 4px;
    box-shadow: 0px 0px 2px vars.$color-black;
    margin-bottom: 1em;

    .justified-gallery-header {
        font-size: 1.5em;
        text-align: right;
        margin: 0;
        padding: 0.7em 0.4em;
        color: vars.$color-d;
        border-top: solid 1px vars.$color-d;
    }
}