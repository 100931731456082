@use 'vars.scss';

ul {
    // font-family: vars.$ul-font;
    // font-weight: vars.$ul-font-weight;
    // font-size: vars.$ul-font-weight;
    // font-size: 1.4em;
    font: vars.$ul-font;
    list-style-position: inside;
    padding-bottom: 0.2em;
    /* account for character height difference to make padding seem equal on top and bottom */

    /* bullets are within managed container */
    /* control bullets */
    list-style: none;

    /* left by default */
    // padding-left: vars.$container-border-margin;

    li::before {
        // margin-right: vars.$bullet-li-spacing;
        content: '\2022';
        position: relative;
        top: 0.075em;
    }

    li.active::before {
        color: vars.$color-d;
    }

    li.with-description {
        padding-left: 1em;
        // padding-bottom: 0.5em;
        text-indent: -1em;
        line-height: 125%;
    }

    .li-description {
        font-size: 1em;
        // padding: 0px 0px 0.2em 21px;
        // padding-left: 21px;
        font-family: vars.$content-font;
    }
}

/* li with date */

li {
    .li-spacing {
        display: none;
    }

    .li-date {
        display: none;
    }
}

.li-with-date {
    li>.li-with-date-item {
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - vars.$container-border-margin);

        .li-spacing {
            display: inline;
            flex-grow: 1;
            border-bottom: dashed 1px #eee;
            margin-left: 0.5em;
            margin-right: 0.5em;
            margin-bottom: 0.3em;
        }

        .li-date {
            display: inline;
            font-size: 0.9em;
            margin-top: auto;
            // padding-bottom: 0.1em;
        }
    }
}

.menu-right {
    ul {
        direction: rtl;
    }
}
