@use 'reset.scss';
@use 'vars.scss';
@use 'globals.scss';
@use 'hr-desktop.scss';

body {
    font-size: 1.1em;
}

#root-container {
    height: fit-content;
}

.section-header {
    font-size: 3em;
}

.left-section {
    width: vars.$main-container-min-width;
    min-width: vars.$main-container-max-width;

    margin-left: vars.$main-margin-left;

    border-right: vars.$color-d 1px solid;
    border-left: vars.$color-d 1px solid;

    box-shadow: 0px 3px 12px vars.$color-d;
}

.right-section {
    width: min(100%, vars.$subsection-container-max-width);
    max-width: 1000px;
}

.content-container {
    h2 {
        font-size: 1.4em;

    }

    h3 {
        font-size: 1.5em;
    }

    p {
        padding: 0 0.2em vars.$container-border-margin 0.2em;
        text-align: justify;
    }
}

/*
account for difference in font size between ul (Iosevka)
and content (Futura) - if <p> is first child of content
container, add negative margin to make margins look
uniform
*/

.content-container > p:first-child {
    margin-top: -0.25em;
}

a:hover.mobile-only {
   // color: inherit; /* was causing `objects` to go white on hover */
   text-decoration: inherit;
   cursor: default;
}