@use 'vars.scss';

.right-section>hr {
    $margin-left: calc(vars.$container-border-margin * -1);
    margin-left: $margin-left;
    width: calc(100% - $margin-left + vars.$container-border-margin);
}

.left-section>hr {
    $margin-left: calc(vars.$main-margin-left * -1);
    margin-left: $margin-left;
    width: calc(100% - $margin-left + vars.$container-border-margin);
}

.left-section {
    hr.left-overflow {
        width: 100vw;
    }
}

.right-section {
    hr.left-overflow {
        width: calc(100vw - vars.$computed-main-v-axis + vars.$container-border-margin/3);
    }
}