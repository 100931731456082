@use 'vars.scss';
@use 'ul.scss';
@use 'colors.scss';
@use 'fonts.scss';
@use 'gallery.scss';

/* globals */


body {
    margin: 0px;
    background-color: vars.$color-a;
    font-family: vars.$content-font;
    // never display horizontal scrollbar
    overflow-x: hidden;
}

#root-container {
    display: flex;
    color: vars.$color-white;
}

.section-header {
    font-family: vars.$section-header-font;
    font-weight: 100;
    letter-spacing: 1px;
    margin-top: calc(vars.$container-border-margin - 0.3em);
    /* account for bottom part of `g` */
    margin-bottom: calc(vars.$container-border-margin - 0.3em);
    /* account for bottom part of `g` */
}

.section-date {
    position: relative;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 1.3em;
    text-align: right;
    height: 1em;
    margin-top: -0.5em;
    margin-bottom: -0.5em;
}


.left-section {
    min-height: 100vh;
    position: relative;
    padding-top: vars.$master-padding-top;
    padding-right: vars.$container-border-margin;

    .section-header {
        text-align: right;
    }
}

.right-section {
    padding: vars.$master-padding-top vars.$container-border-margin;

    hr {
        /* account for right-section padding, move hr back to left edge of the screen */
        margin-left: calc(-1 * vars.$container-border-margin);
    }

    min-height: 100vh;
    height: fit-content;

    .section-header.right {
        text-align: right;
    }
}

.content-container {
    margin: vars.$container-border-margin 0;
    height: fit-content;

    h1,
    h2,
    h3,
    h4 {
        font-family: vars.$h-font;
        // font-weight: 600;
        // letter-spacing: -2px;
    }

    ul {
        /* display all text lowercased, account for change in optical heigh */
        text-transform: lowercase;
        margin: -0.4em 0;

        li {
            margin: 0.2em 0;
        }
    }

    h2,
    h3 {
        margin-top: -0.3em;
        margin-bottom: 0.5em;
    }


    *+h2 {
        margin-top: 0.6em;
    }

    *+h3 {
        margin-top: 1em;
    }

    p {
        font-size: larger;
        max-width: 40em;
    }

}

hr {
    height: 1px;
    border: none;
    background-color: vars.$color-d;
}

/* links */

a {
    color: inherit;
    text-decoration: inherit;
}

a:hover {
    text-decoration: underline;
    text-decoration-thickness: 3%;
    text-underline-offset: 8%;
    text-decoration-color: inherit;
}

.code {
    font-family: vars.$code-font;
    font-size: 0.8em;
    // text-decoration: underline 1px;
    // text-underline-offset: 10%;
}

a.code {
    font-size: 0.9em;
    // text-decoration: underline 1px;
    color: vars.$color-d;
    background-color: antiquewhite;
    border-radius: 4px;
    padding: 0.0em 0.2em 0.1em 0.2em;
    border: 1px solid vars.$color-d;
}

.pdfobject-container {
    height: 120vh;
}

/* hacks */


/* Safari only */
@supports (background: -webkit-named-image(i)) {
    .content-container {
        h1,
        h2,
        h3,
        h4 {
            /* fix discrepancies in letter spacing in safari */
            letter-spacing: -1px;

        }
    }
}