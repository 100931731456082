@font-face {
    font-family: 'futura';
    src: url('/assets/fonts/futura.woff2') format('woff2'),
        url('/assets/fonts/futura.woff') format('woff'),
        url('/assets/fonts/fitura.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'futura-light';
    src: url('/assets/fonts/futura-light.woff2') format('woff2'),
        url('/assets/fonts/futura-light.woff') format('woff'),
        url('/assets/fonts/fitura-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'iosevka-light';
    src: url('/assets/fonts/iosevka-light.woff2') format('woff2'),
        url('/assets/fonts/iosevka-light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'iosevka-regular';
    src: url('/assets/fonts/iosevka-regular.woff2') format('woff2'),
        url('/assets/fonts/iosevka-regular.ttf') format('truetype');
}